<template>
	<div ref="el" class="swiper">
		<div class="swiper-wrapper">
			<slot />
		</div>

		<slot name="controls" />
	</div>
</template>

<script lang="ts" setup>
	import Swiper from 'swiper'
	import { A11y, Autoplay, EffectFade, FreeMode } from 'swiper/modules'
	import type { SwiperOptions } from 'swiper/types'

	const props = defineProps<{
		options?: SliderOptions
	}>()

	const emit = defineEmits<{
		'slider:ready': [Swiper]
		'slider:change': [Swiper]
	}>()

	let swiper: Swiper | null = null

	const el = ref<HTMLElement>(null!)

	const activeIndex = ref(0)

	const isBeginning = ref(false)

	const isEnd = ref(false)

	const slides = ref<HTMLElement[]>([])

	onMounted(() => {
		swiper = new Swiper(el.value, {
			a11y: {
				firstSlideMessage: 'First slide',
				itemRoleDescriptionMessage: 'slide',
				lastSlideMessage: 'Last slide',
				nextSlideMessage: 'Next slide',
				paginationBulletMessage: 'Go to slide {{index}}',
				prevSlideMessage: 'Previous slide',
				slideLabelMessage: 'Slide {{index}}',
				slideRole: 'group',
			},
			modules: [
				A11y,
				Autoplay,
				EffectFade,
				FreeMode,
				// Navigation,
				// Pagination
			],
			on: {
				init: (swiper) => {
					activeIndex.value = swiper.realIndex
					isBeginning.value = swiper.isBeginning
					isEnd.value = swiper.isEnd
					slides.value = swiper.slides

					emit('slider:ready', swiper)
				},
				slideChange: (swiper) => {
					activeIndex.value = swiper.realIndex
					isBeginning.value = swiper.isBeginning
					isEnd.value = swiper.isEnd

					emit('slider:change', swiper)
				},
				slidesLengthChange: (swiper) => {
					slides.value = swiper.slides
				},
			},
			...props.options,
		})
	})

	function next() {
		swiper?.slideNext()
	}

	function prev() {
		swiper?.slidePrev()
	}

	function slideTo(index: number, speed?: number, runCallbacks?: boolean) {
		if (props.options?.loop) {
			swiper?.slideToLoop(index, speed, runCallbacks)
		} else {
			swiper?.slideTo(index, speed, runCallbacks)
		}
	}

	defineExpose({
		activeIndex,
		next,
		prev,
		slides,
		slideTo,
		isBeginning,
		isEnd,
	})
</script>

<script lang="ts">
	export type SliderOptions = Omit<SwiperOptions, 'a11y' | 'modules' | 'on'>
</script>

<style lang="scss">
	@layer components {
		@import '../../../../node_modules/swiper/swiper';
		@import '../../../../node_modules/swiper/modules/a11y';
		@import '../../../../node_modules/swiper/modules/autoplay';
		@import '../../../../node_modules/swiper/modules/controller';
		@import '../../../../node_modules/swiper/modules/effect-fade';
		@import '../../../../node_modules/swiper/modules/free-mode';
		// @import '../../../../node_modules/swiper/modules/navigation';
		// @import '../../../../node_modules/swiper/modules/pagination';

		.swiper {
			visibility: hidden;

			&.swiper-initialized {
				visibility: visible;
			}

			.swiper-button-prev,
			.swiper-button-next {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				z-index: 1;
				padding: 20px;
			}

			.swiper-button-prev {
				left: 0;
			}

			.swiper-button-next {
				right: 0;
			}

			.swiper-dots {
				position: absolute;
				bottom: 15px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 1;
			}
		}
	}
</style>
