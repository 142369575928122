<template>
	<div
		:class="{
			'swiper-button-prev': type === 'prev',
			'swiper-button-next': type === 'next',
		}">
		<v-button
			ref="el"
			:color="color ?? 'white'"
			:fill="fill ?? 'solid'"
			:icon="type === 'prev' ? 'tepari:chevron-left' : 'tepari:chevron-right'"
			shape="round"
			size="lg"
			type="button"
			@click="onClick">
			<v-sr-only :value="type === 'prev' ? 'previous' : 'next'" />
		</v-button>
	</div>
</template>

<script lang="ts" setup>
	import type { ButtonColor, ButtonFill } from '../button.vue'
	import Slider from './slider.vue'

	const props = defineProps<{
		slider?: InstanceType<typeof Slider>
		type: 'prev' | 'next'
		color?: ButtonColor
		fill?: ButtonFill
	}>()

	function onClick() {
		if (props.type === 'next') props.slider?.next()
		if (props.type === 'prev') props.slider?.prev()
	}
</script>

<style lang="scss" scoped></style>
